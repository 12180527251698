* {
    margin:0;
    padding: 0;
}
html, body {
    min-height: 100%;
}
body {
    font: 200 100%/1.4 'Macan', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-bottom: 6em;
    letter-spacing: .01em;
}
footer, main {
    max-width: 1024px;
    margin: 0 auto;
}
header {
    background: #fafbfc;
    color: #404145;
    text-align: center;
    padding: 3em;
    margin: 0 0 1em;
    border-bottom: 1px solid #404145;
    letter-spacing: -2px;

    .icon {
        background: #1dbf73;
        border-radius: 8px;
        padding: 18px 24px 18px 22px;
    }
    .logo {
        height: .75em;
    }
    h1 {
        margin: .5em 0 0;
    }
}
a {
    text-decoration: none;
    color: inherit;
}
footer {
    padding: 1em;
}

.project-card {
    flex: 1;
    flex-basis: 98%;
    max-width: 98%;
    margin: 1%;
    list-style-type: none;
    box-shadow: 0 0 0 1px var(--borders-default);
    transition: box-shadow .3s ease-out;

    * {
        background: inherit;
    }

    a {
        display: block;
        position: relative;
    }

    h3 {
        padding: 1em;
        min-height: 3em;
        box-shadow: 0 1px 0 var(--borders-default);
        transition: box-shadow .2s ease-out;
        display: flex;
        align-items: flex-end;
        white-space: break-spaces;
    }

    .language-badge {
        float: right;
        font-size: .8em;
        margin: 1.4em 1em 0em 0.5em;
        font-weight: 400;
        padding: 0.2em 0.4em 0;
        border-radius: .5em;
    }

    .stars {
        text-decoration: none;
        font-size: .8em;
        margin-left: .4em;
    }

    p {
        padding: 1em;
    }

    @media (min-width: 600px) {
        flex-basis: 48%;
        max-width: 48%;
    }

    @media (min-width: 800px) {
        flex-basis: 23%;
        max-width: 23%;
    }

    &:hover {
        box-shadow: 0 0 0 1px var(--borders-hover);

        h3 {
            box-shadow: 0 1px 0 var(--borders-hover);
        }
    }

    &.javascript {
        h3,
        span {
            background: #f0db4f;
        }
    }

    &.typescript {
        h3,
        span {
            background: #007acc;
        }
    }

    &.ruby {
        h3,
        span {
            background: #d91404;
        }
    }

    &.docker {
        h3,
        span {
            background: #2496ed;
        }
    }

    &.python {
        h3,
        span {
            background: #3572A5;
        }
    }
}
